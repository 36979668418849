<template>
    <div class="px-3 md:px-0">
        
        <div v-if="materiKonten != null && !isLoading">
            <konten-materi v-if="materiKonten.jenis_konten == 'materi'" :materi_konten="materiKonten" :gotoKonten="gotoKonten"/>
            <konten-kuis v-if="materiKonten.jenis_konten == 'kuis'" :materi_konten="materiKonten"/>
        </div>

        <loading-modal :isShow="isLoading"/>
   </div>
</template>
<script>
    import KontenKuis from '../../components/KontenKuis.vue'
    import KontenMateri from '../../components/KontenMateri.vue'
    import LoadingModal from '../../components/LoadingModal.vue'
    import { errorHandler, showErrorNotif } from '../../helpers/Tools'

    export default {
        components: { KontenMateri, KontenKuis, LoadingModal },
        name: 'ViewMateriKonten',
        data(){
            return {
                isLoading   : true,
                materiKonten: null
            }
        },
        watch: {
            "$route.params.id_materi_konten": function (id) {
                this.getDetailKonten();
            },
        },
        mounted() {
            this.getDetailKonten()
        },
		methods : {
            async getDetailKonten() {
                this.isLoading = true;
                try {
                    let res = await this.$store.dispatch("getDetailKontenMateri", {
                        id_materi       : this.$route.params.id_materi,
                        id_materi_konten: this.$route.params.id_materi_konten,
                    });
                    let result = res.data
                    this.isLoading = false;
                    if(result.status == 'success') {
                        this.materiKonten = result.data;
                        if(result.data.jenis_konten == 'kuis' && result.data.user_history != null) {
                            
                            if(result.data.skor != null) {

                                this.$router.replace({ name: 'PembahasanKuisMateri', params: { 
                                    singkatan_kategori    : this.$route.params.singkatan_kategori.toLowerCase(),
                                    singkatan_sub_kategori: this.$route.params.singkatan_sub_kategori.toLowerCase(),
                                    id_materi             : this.$route.params.id_materi,
                                    id_materi_konten      : this.$route.params.id_materi_konten,
                                    id_materi_user_skor   : result.data.skor.id_materi_user_skor
                                }});

                            } else {
                                showErrorNotif(this, "Anda sudah menjawab kuis");
                                this.$router.replace({ name: 'MateriSubKategoriList', params: { 
                                    singkatan_kategori    : this.$route.params.singkatan_kategori.toLowerCase(),
                                    singkatan_sub_kategori: this.$route.params.singkatan_sub_kategori.toLowerCase(),
                                    
                                }});
                            }
                            
                            
                        }
                    } else {

                        if(typeof result.data.tipe != 'undefined') {
                            if(result.data.tipe  == 'redirect') {
                                let lastest_konten = result.data.konten
                                // redirect to konten sebelumnya
                                this.$router.replace({ name: 'ViewMateriKonten', params: { 
                                    singkatan_kategori    : this.$route.params.singkatan_kategori.toLowerCase(),
                                    singkatan_sub_kategori: this.$route.params.singkatan_sub_kategori.toLowerCase(),
                                    id_materi             : lastest_konten.id_materi,
                                    id_materi_konten      : lastest_konten.id_materi_konten
                                }});
                            } else if(result.data.tipe == 'materi_locked') {
                                showErrorNotif(this, result.message);
                                this.$router.replace({ name: 'MateriSubKategoriList', params: { 
                                    singkatan_kategori    : this.$route.params.singkatan_kategori.toLowerCase(),
                                    singkatan_sub_kategori: this.$route.params.singkatan_sub_kategori.toLowerCase(),
                                }});
                            }
                        } else {
                            showErrorNotif(this, result.message);
                        }
                    }
                } catch (error) {
                    this.isLoading = false;
                    errorHandler(this, error);
                }
            },

            async gotoKonten (materi_konten_sumber, materi_konten_selanjutnya) {
                try {

                    let res = await this.$store.dispatch("getDetailKontenMateri", {
                        id_materi              : materi_konten_selanjutnya.id_materi,
                        id_materi_konten       : materi_konten_selanjutnya.id_materi_konten,
                        id_materi_konten_sumber: materi_konten_sumber.id_materi_konten
                        
                    });
                    let result = res.data
                    this.isLoading = false;
                    
                    if(result.status == 'success') {
                        if(materi_konten_selanjutnya.id_materi_konten == materi_konten_sumber.id_materi_konten) {
                            this.$router.replace({ name: 'MateriSubKategoriList', params: { 
                                singkatan_kategori    : this.$route.params.singkatan_kategori.toLowerCase(),
                                singkatan_sub_kategori: this.$route.params.singkatan_sub_kategori.toLowerCase(),
                            }});
                        } else {
                            this.$router.replace({ name: 'ViewMateriKonten', params: { 
                                singkatan_kategori    : this.$route.params.singkatan_kategori.toLowerCase(),
                                singkatan_sub_kategori: this.$route.params.singkatan_sub_kategori.toLowerCase(),
                                id_materi             : materi_konten_selanjutnya.id_materi,
                                id_materi_konten      : materi_konten_selanjutnya.id_materi_konten
                            }});
                        }
                    } else {
                        showErrorNotif(this, result.message);
                    }
                } catch (error) {
                    this.isLoading = false;
                    errorHandler(this, error);
                }
            }
        }
    }
</script>
