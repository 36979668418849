<template>
    <div class="">
        
        <div class="hero px-4 py-4 w-full ">
            <div class="flex space-x-2 items-center">
                <div class="h-12 w-12 bg-yellow-400 rounded-lg flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mx-auto text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                    </svg>
                </div>
                <div class="flex-wrap">
                    <p class="font-semibold">{{ materi_konten.materi.judul_materi }}</p>
                    <p class="text-xl leading-8 font-semibold tracking-tight capitalize">
                        {{ materi_konten.judul_konten }}
                    </p>
                </div>
            </div>
        </div>  

        <div class="flex-grow bg-white px-3 py-3 rounded-lg" v-if="materi_konten.tipe_konten == 'iframe' ">
            <iframe style="max-width:100%; margin: 0 auto;" :src="materi_konten.isi_konten" width="500" height="380" frameborder="0" allowfullscreen></iframe>
        </div>

        <div class="flex-grow bg-white px-3 py-3 rounded-lg" v-if="materi_konten.tipe_konten == 'text' ">
            <div v-html="materi_konten.isi_konten" />
        </div>


        <div class="rounded-lg overflow-hidden flex flex-col" v-if="materi_konten.tipe_konten == 'pdf' && isMobile() == false ">
            <div class='pdf-viewer-wrapper bg-blue-50' v-dragscroll='true' :style="{'height' : `calc(${windowHeight}px - ${minusHeight}px)`}">
                <pdf :src="`${storagePath}`" :style="{ width: `${zoom}%` }" 
                    :page="page"
                    @num-pages="pageCount = $event"
			        @page-loaded="currentPage = $event">
                </pdf>
            </div>
            <div class="bg-gray-800  p-1 text-center rounded-b-lg flex items-center space-x-2">
                <div class="flex">
                    <button @click="page += 1" class="p-2 bg-gray-800 rounded hover:bg-gray-700 focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                        </svg>
                    </button>
                    <div class="inline-block bg-gray-700 p-2 text-sm text-white rounded">{{currentPage}} / {{pageCount}}</div>
                    <button @click="page += 1" class="p-2 bg-gray-800 rounded hover:bg-gray-700 focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                        </svg>
                    </button>
                </div>

                <button @click="zoom += 20" class="p-2 bg-gray-800 rounded hover:bg-gray-700 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7" />
                    </svg>
                </button>
                <button @click="zoom -= 20" class="p-2 bg-gray-800 rounded hover:bg-gray-700 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM13 10H7" />
                    </svg>
                </button>
            </div>
        </div>

        <div v-if="materi_konten.tipe_konten == 'pdf' && isMobile() == true " class="flex-grow flex items-center">
            <div class="h-64 w-64 bg-gray-100 rounded-full m-auto text-center flex flex-col items-center space-y-2 p-4 pt-16">
                <div>
                    <img src="@/assets/images/study.svg" class="w-1/3 mx-auto"/>
                </div>
                <h2 class="text-sm">Materi disajikan dalam bentuk pdf</h2>
                <button @click="previewPdf(materi_konten.isi_konten)" class="p-1 px-2 bg-yellow-400 rounded-md text-white focus:outline-none">Lihat Materi</button>
            </div>
        </div>

        <div class="rounded-md overflow-hidden">
        <vue-plyr :options="options" v-if="materi_konten.tipe_konten == 'video' && isMobile() == false">
            <video  controlsList="nodownload" v-if="materi_konten.tipe_konten == 'video' && isMobile() == false" controls>
                <source :src="`${storagePath}`"/>
            </video>
        </vue-plyr>
        </div>

        <div v-if="materi_konten.tipe_konten == 'video' && isMobile() == true " class="flex-grow flex items-center">
            <div class="h-64 w-64 bg-gray-100 rounded-full m-auto text-center flex flex-col items-center space-y-2 p-4 pt-16">
                <div>
                    <img src="@/assets/images/video.svg" class="w-1/3 mx-auto"/>
                </div>
                <h2 class="text-sm">Materi disajikan dalam bentuk video</h2>
                <button @click="previewVideo(materi_konten.isi_konten)" class="p-1 px-2 bg-yellow-400 rounded-md text-white  focus:outline-none">Lihat Video</button>
            </div>
        </div>

        <!-- navigation -->
        <div class="h-10 md:h-20"></div>

        <div  class="py-4 px-3 bg-white w-full fixed md:bottom-0 bottom-12 right-0 left-0 border-t-2 border-gray-100">
            <div class="max-w-4xl mx-auto grid grid-cols-2 space-x-1">
                
                <button @click="gotoKonten(materi_konten, materi_konten.prev)"  class="
                    relative justify-center py-2 px-4 border border-transparent text-sm 
                    font-medium rounded-md text-gray-600 bg-gray-100 
                    focus:outline-none" :class="materi_konten.prev == null ? 'invisible' : '' ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-500 inline-block "  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
                    </svg>
                    Sebelumnya
                </button>

                <button @click="gotoKonten(materi_konten, materi_konten.next)" v-if="materi_konten.next != null" class="
                    group relative w-auto flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md 
                    bg-blue-500 text-white focus:outline-none
                    ml-auto">
                    Selanjutnya
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-200 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                </button>
                <button @click="gotoKonten(materi_konten, materi_konten)" v-if="materi_konten.next == null" class="
                    group relative w-auto flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md 
                    text-white bg-blue-600 focus:outline-none
                    ml-auto">
                    Selesai
                </button>

            </div>
        </div>

    </div>
</template>

<script>
import { isMobile } from '../helpers/Tools'
import pdf from 'vue-pdf'
import { dragscroll } from 'vue-dragscroll'

export default {
    name: 'KontenMateri',
    directives: { dragscroll },
    components : {
        pdf
    },
    data(){
        return {
            windowHeight: window.innerHeight,
            minusHeight : 0,
            zoom        : 100,
            currentPage : 0,
            pageCount   : 0,
            storagePath : null,
            page        : 1,
            options     : { quality: { default: '1080p' } }
        }
    },
    props: {
        materi_konten : {
            type : Object,
        },
        gotoKonten : {
            type : Function
        }
    },
    mounted() {
        this.storagePathKonten();
        this.onResize();
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
    methods : {
        isMobile() {
            return isMobile();
        },

        onResize() {
            this.windowHeight = window.innerHeight
            let width = window.innerWidth;
            if(width > 640) {
                this.minusHeight = 300;
            } else {
                this.minusHeight = 260;
            }
        },

        async storagePathKonten() {
            if(this.materi_konten.tipe_konten == 'video' || this.materi_konten.tipe_konten == 'pdf') {
                let url = await this.$store.dispatch('addTokenToUrl', this.materi_konten.isi_konten);
                this.storagePath = url;
            } 
        },
        async previewPdf(url) {
            url = await this.$store.dispatch('addTokenToUrl', url);
            if(typeof window.flutter_inappwebview != 'undefined') {
                window.flutter_inappwebview.callHandler('instancy.pdfViewer', url);
            } else {
                window.open(url, '_blank');
            }
        },
        async previewVideo(url) {
            url = await this.$store.dispatch('addTokenToUrl', url);
            if(typeof window.flutter_inappwebview != 'undefined') {
                window.flutter_inappwebview.callHandler('instancy.videoViewer', url);
            } else {
                window.open(url, '_blank');
            }
        }
    }
}
</script>

<style>
    .pdf-viewer-wrapper {
        overflow: hidden;
        cursor: grab;
    }
</style>