<template>
    <div class="pt-16 pb-10" v-if="materi_konten != null">
        <div class="w-40 mx-auto h-40 p-8 rounded-full bg-white shadow">
            <img src="@/assets/images/choose.svg" class="w-30 h-30" />
        </div>
        <div class="md:w-full mx-auto mt-2">
            <h2 class="text-center text-3xl font-semibold tracking-tight mt-4">{{ materi_konten.judul_konten }}</h2>
            <p class="text-center">Instruksi Menjawab.</p>
            <div class="border-2 rounded-lg bg-white px-4 py-3 mt-2" v-html="materi_konten.kuis.instruksi"></div>
        </div>

     
        <div class="py-4 px-3 bg-white w-full fixed md:bottom-0 bottom-12 right-0 left-0 border-t-2 border-gray-100">
            <div class="max-w-4xl mx-auto grid grid-cols-2 space-x-1">
                
                <div></div>

                <button @click="mulaiKerjakanKuis()" class="
                    group relative w-auto flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md 
                    bg-blue-500 text-white focus:outline-none
                    ml-auto">
                    Mulai Kerjakan Kuis
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-200 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                </button>

            </div>
        </div>


        <loading-modal :isShow="isLoadingKerjakanKuis" />
    </div>
</template>

<script>
import { errorHandler, showErrorNotif, showSuccessNotif } from '../helpers/Tools';
import LoadingModal from './LoadingModal.vue'
export default {
    components: { LoadingModal },
    name: 'KontenKuis',
    props: {
        materi_konten : {
            type : Object,
        }
    },
    data() {
        return {
            isLoadingKerjakanKuis : false,
        }
    },
    computed: {
        konten () {
            return this.$store.getters.getKonten
        }
    },
    methods : {
        async mulaiKerjakanKuis() {
            this.isLoadingKerjakanKuis = true;
            try {

                let res = await this.$store.dispatch("startKuisMateri", {
                    id_materi       : this.materi_konten.id_materi,
                    id_materi_konten: this.materi_konten.id_materi_konten,
                });
                let result = res.data;
                this.isLoading = false;

                if(result.status == 'success') {
                    showSuccessNotif(this, result.message);

                    let resultData = result.data;

                    this.$router.replace({ name: 'JawabKuisMateri', params: { 
                        singkatan_kategori    : this.$route.params.singkatan_kategori.toLowerCase(),
                        singkatan_sub_kategori: this.$route.params.singkatan_sub_kategori.toLowerCase(),
                        id_materi             : this.materi_konten.id_materi,
                        id_materi_konten      : this.materi_konten.id_materi_konten,
                        token                 : resultData.token
                    }});

                } else {
                    this.isLoadingKerjakanKuis = false;
                    showErrorNotif(this, result.message);
                }

            } catch (error) {
                this.isLoadingKerjakanKuis = false;
                errorHandler(this, error);
            }
        }
    }
}
</script>
